import React from 'react'
import styled from 'styled-components';
import { Card } from 'primereact/card';
import classNames from 'classnames';
import BlackCardContainer from './BlackCardContainer';

export const AnswersGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Answers = styled.div`
  display: grid;
  // width: 10rem;
  :hover {
    cursor: pointer;
  }
`

export const AnswerCardContainer = styled(Card)`
  width: 10rem; 
  height: 10rem;
  transition: all 0.2s ease-out;
  .p-card-title {
    font-size: 1rem;
  }
  :not(:first-child) {
    transform: translateY(${(props) => props.childnum * -40}%);
  }
    box-shadow: -3px 3px #000;
    border-radius: 6px;
    border: 1px solid #000;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 1rem;
  padding: 0;
  display: flex;
  grid-gap: 1rem;
  flex-direction: column;
`

function AnswersContainer({
  answers,
  handlePickWinnerClick,
  questionCard,
}) {

  const renderAnswerCard = (answerer, index) => {
    return (
      <BlackCardContainer
        key={answerer}
        className={classNames("black-card-container", answerer)}
        title={questionCard}
        answers={answers[answerer]}
        childnum={index} />
    )
  }

  return (answers &&
    <Container className={classNames("AnswersContainer")}>
      {Object.keys(answers).map(a => (
        <AnswersGroup onClick={() => handlePickWinnerClick(a)} key={a}>
          {renderAnswerCard(a, questionCard)}
        </AnswersGroup>
      ))}
    </Container>
  )
}

AnswersContainer.propTypes = {}

export default AnswersContainer
