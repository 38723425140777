import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const TopTitle = styled.h1`
  margin: 2rem 0 3rem 0;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-fill-mode: backwards;
  
  font-size: 3rem;
  
  @keyframes animate-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const BottomTitle = styled.h1`
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-fill-mode: backwards;

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  font-size: 6rem;
  
`

const Image = styled.img`
  position: absolute;
  object-fit: scale-down;
  width: 100%;
  height: 100%;

  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  
  animation-delay: 2s;
  animation-duration: 0.1s;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
`

const ImageSmile = styled.img`
  position: absolute;
  object-fit: scale-down;
  height: 100%;
  width: 100%;

  animation-delay: 2s;
  animation-duration: 0.1s;
  animation-name: fadeIn;
  animation-fill-mode: backwards;

`

const ImageContainer = styled.div`
  position: relative;
  width: 16rem;
  height: 16rem;
  overflow: hidden;
`

const Splash = () => {
  return (
    <Container>
      <TopTitle>ברוכים הבאים</TopTitle>
      <ImageContainer>
        <Image src={ `${ process.env.PUBLIC_URL }/assets/images/me_gusta.png` } alt="Gush Logo"/>
        <ImageSmile src={ `${ process.env.PUBLIC_URL }/assets/images/me_gusta_smile.png` } alt="Gush Logo"/>
      </ImageContainer>
      <BottomTitle>למשחק</BottomTitle>
    </Container>
  );
};

export default Splash;