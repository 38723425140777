import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import {
  ButtonsContainer,
  QuestionContainer,
  AnsweredPlayers,
  NextPlayerButton,
  WinnerVerificationDialog,
  AnsweredPlayerBox
} from './style';

import AnswersContainer from './AnswersContainer';
import BlackCardContainer from './BlackCardContainer';

import { GameMode } from '../../models/Enums'
import { classNames } from 'primereact/utils';


function BlackCardPlayer({
  onModeChange,
  answers,
  questionCard,
  onNextPlayerClicked,
  onPickWinnerClicked,
  onGetAnswersClicked,
  answeredPlayers,
  totalNumberOfAnswers
}) {
  const [didPickWinner, setDidPickWinner] = useState(false)

  const acceptWinner = (player) => {
    onPickWinnerClicked(player)
    setDidPickWinner(true)
  }

  const handlePickWinnerClick = (player) => {
    if (didPickWinner) return
    confirmDialog({
      message: 'בדוק?',
      accept: () => acceptWinner(player),
      acceptLabel: 'כן',
      rejectLabel: 'לא',
      showHeader: false,
      contentStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
      },
    });

  }

  const handleGetAnswersClick = () => {
    onGetAnswersClicked()
  }

  const handleMoveToNextPlayerClick = () => {
    onModeChange(GameMode.ANSWERER)
    onNextPlayerClicked()
  }

  const renderAnsweredPlayers = () => (
    answeredPlayers.map(a => (
      <AnsweredPlayerBox>
        {a}<br />
      </AnsweredPlayerBox>
    ))
  )
  return (
    <>
      <WinnerVerificationDialog />
      <QuestionContainer className={classNames("question-container")}>
        {didPickWinner &&
          <NextPlayerButton className="p-button-outlined" onClick={() => handleMoveToNextPlayerClick()}>עבור לשחקן
            הבא</NextPlayerButton>}
        {
          Object.keys(answers).length === 0 &&
          <BlackCardContainer
            className={classNames("black-card-container")}
            title={questionCard.replaceAll("X", "_")} />
        }
        <AnswersContainer
          handlePickWinnerClick={handlePickWinnerClick}
          questionCard={questionCard}
          answers={answers}
          onGetAnswersClicked={onGetAnswersClicked} />
      </QuestionContainer>
      {Object.keys(answers).length === 0 &&
        <AnsweredPlayers>
          מי ענה <br /><br />
          {renderAnsweredPlayers()}
        </AnsweredPlayers>}
      <ButtonsContainer>
        {Object.keys(answers).length === 0 &&
          <Button
            style={{ margin: '1rem auto', }}
            onClick={() => handleGetAnswersClick()}>תשובות</Button>}
      </ButtonsContainer>
    </>
  )
}

BlackCardPlayer.propTypes = {
  sendMessage: PropTypes.func,
  onModeChange: PropTypes.func.isRequired,
  answers: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  questionCard: PropTypes.string.isRequired,
  onNextPlayerClicked: PropTypes.func.isRequired,
  onPickWinnerClicked: PropTypes.func.isRequired,
  onGetAnswersClicked: PropTypes.func.isRequired,
  answeredPlayers: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalNumberOfAnswers: PropTypes.number.isRequired,
}

BlackCardPlayer.defaultProps = {
  sendMessage: () => {
  },
}

export default BlackCardPlayer
