import React from 'react';
import styled from 'styled-components'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Game from '../Game'


const AppContainer = styled.div`
  padding: 0;
`

function App() {

  const router = createBrowserRouter([
    {
      path: "/game/:game",
      element: <Game/>
    },
    {
      path: "/",
      element: <Game/>
    }
  ]);

  return (
    <AppContainer>
      <RouterProvider router={router} />
    </AppContainer>
  );
}

export default App;
