import classNames from 'classnames'
import { Card } from 'primereact/card'
import React from 'react'
import styled from 'styled-components'


const CardContainer = styled.div`
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
    max-width: 10rem;
    box-shadow: -3px 3px #000;
    border-radius: 6px;
    border: 1px solid #000;
    font-size: 1rem;
    line-height: 100%;
    
    
  &.selected {
    background: black;
    color: white;
    transform: translate(-3px, 3px);
    box-shadow: none;
  }
  :hover {
    cursor: pointer;
  }
`

export const CardFooter = styled.div`
  font-size: 0.7rem;
  border: blue 2px solid;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  padding-top: 0.1rem;
`


export default function WhiteCard({
    text, selectedCardIndex, onCardClicked, isSelected = false
}) {
    const className = classNames('card-white', { selected: isSelected })
    return (
        <CardContainer
            className={className}
            key={text}
            onClick={() => onCardClicked(text)}>{text}</CardContainer>
    )
}
