import React from 'react';
import styled from 'styled-components';


export const Container = styled.div`
  background-color: #000;
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  border-radius: 6px;
  padding: 1rem;
  p {
    color: #fff;
    font-size: 1rem;
    text-align: center;
    width: 100%;
  }
`

export default function BlackCardContainer({ title, numRequiredAnswers = 1, answers = [] }) {
  const calcRequiredAnswers = Math.max(numRequiredAnswers, answers.length)
  const setupAnswer = () => {
    let tempQCard = title.replaceAll("X", "_")
    if (!tempQCard.includes("_")) {
        return (
          <>
            {tempQCard}
            <br/>
            "
            {Array.from(Array(calcRequiredAnswers).keys()).map((i) => (
              <>
                {answers.length > i ? answers[i].text : "_____"}
                {i === calcRequiredAnswers - 1 ? '"' : <br/>}
              </>
            ))}
            
          </>
        )
    } else {
      let index = tempQCard.indexOf("_");
      let ansIndex = 0
      while (index > -1 && ansIndex < answers.length) {
        tempQCard = tempQCard.substring(0, index) +
          answers[ansIndex].text +
          tempQCard.substring(index + 5, tempQCard.length);
        index = tempQCard.indexOf("_");
        ansIndex += 1;
      }
    }
    return tempQCard
  }
  return (
    <Container>
      <p>{setupAnswer()}</p>
    </Container>
  )
}
