export const S_DRAW_CARDS = "DRAW_CARDS"
export const S_START_GAME = "START_GAME"
export const S_GET_QUESTION = "GET_QUESTION"
export const S_SEND_ANSWER = "SEND_ANSWER"
export const S_GENERATE_RANDOM = "GENERATE_RANDOM"
export const S_GET_ANSWERS = "GET_ANSWERS"
export const S_PICK_WINNER = "PICK_WINNER"
export const S_WINNER = "WINNER"
export const S_NEXT_PLAYER = "NEXT_PLAYER"
export const S_STOP_GAME = "STOP_GAME"

export const C_WINDOW_HEIGHT = '90vh'