import { Button } from "primereact/button"
import styled from "styled-components"


export const Container = styled.div`
  margin: 1rem 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CarouselWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  align-self: stretch;
  margin: 0 0 1rem 0;
  gap: 1rem;
`

export const SendAnswerButton = styled(Button)`
`