import React from 'react';
import ReactDOM from 'react-dom/client';
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './index.css';
import App from './containers/App/App';
import axios from "axios";
import * as Sentry from "@sentry/react";


if (process.env.REACT_APP_NODE_ENV === "production") {
    axios.defaults.baseURL = `https://${process.env.REACT_APP_BACKEND_URL}`;
} else {
    axios.defaults.baseURL = `http://${process.env.REACT_APP_BACKEND_URL}`;
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment: process.env.REACT_APP_NODE_ENV === "production" ? "PROD" : "DEV",
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});    

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
