import React from 'react'
import styled from 'styled-components'
import {ReadyState} from 'react-use-websocket'

const Container = styled.div`
  height: 3rem;
  display: grid;
  grid-template-columns: 1fr 5rem;
  width: 100vw;
  background-color: #f5f5f5;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

const MenuBarStartSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
`

const MenuBarEndSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3rem;
`

const ConnectionDot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100rem;
  background-color: ${props => props.didConnect ? '#00ff00'
          : props.isConnecting ? '#ffff00'
                  : '#ff0000'};
`

const Logo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  img {
    width: 2rem;
    height: 2rem;
  }
`

const PlayerName = styled.h5`
  margin-left: 1rem;
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
`

const GameCode = styled.h5`
  margin-left: 1rem;
`

function GushMenuBar({
                         connectionState = ReadyState.CLOSED,
                         playerName,
                         gameCode,
                     }) {
    return (
        <Container>
            <MenuBarStartSide>
                <Logo>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/me_gusta_smile.png`} alt="Gush Logo"/>
                </Logo>
                <PlayerName>{playerName}</PlayerName>
            </MenuBarStartSide>
            <MenuBarEndSide>
                <GameCode>{gameCode}</GameCode>
                <ConnectionDot
                    isConnecting={connectionState === ReadyState.CONNECTING}
                    didConnect={connectionState === ReadyState.OPEN}/>
            </MenuBarEndSide>
        </Container>
    )
}

GushMenuBar.propTypes = {}

export default GushMenuBar
