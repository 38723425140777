import React from 'react'
import styled from 'styled-components';
import classNames from 'classnames';

export const DeckCheckBox = styled.div`
  margin: 0 0 1rem 1rem;
  box-shadow: -3px 3px #000;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 6px;
  margin-right: 0.25rem;
  background: white;
  
  &:hover {
    background: #f1f1f1;
    cursor: pointer;
  }

  &.selected {
    background: black;
    color: #f8f8f8;
    box-shadow: none;
    transform: translate(-3px, 3px);
  }
`

export default function DeckBox({ deck, onDeckPicked, isSelected = false }) {
  const className = classNames("DeckBox", {"selected": isSelected })
  const onDeckClicked = e => onDeckPicked(deck, !isSelected)
  return (
    <DeckCheckBox
      className={className}
      key={deck}
      onClick={onDeckClicked}
      >{deck}</DeckCheckBox>
  )
}
