import styled from 'styled-components'

import { C_WINDOW_HEIGHT } from '../../utils/consts'

export const CoverContainer = styled.div`
  width: 100%;
  height: ${C_WINDOW_HEIGHT};
  background-color: #f4f8f8;
  display: flex;
`

export const TextContainer = styled.div`
  margin: auto auto;
`

export const CoverText = styled.h1`
  color: #fe0000;
  text-align: center;
  margin: auto auto;
  transform: rotate(-15deg);
  font-size: 5em;
`