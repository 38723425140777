import styled from 'styled-components'
import { Button } from "primereact/button";
import { ConfirmDialog } from 'primereact/confirmdialog';


export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
`


export const QuestionContainer = styled.div`
  margin-top: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AnsweredPlayers = styled.p`
    text-align: center;
`

export const NextPlayerButton = styled(Button)`
  padding: 0 2rem;
  height: 2.5rem;
  display: block;
`

export const WinnerVerificationDialog = styled(ConfirmDialog)`
`
export const AnsweredPlayerBox = styled.div`
  margin: 0.25rem;
`