import * as consts from '../utils/consts';


export const StartGameRequest = () => (
  JSON.stringify({
    type: consts.S_START_GAME
  })
)

// export const DrawCardsRequest = count => (
//   JSON.stringify({
//     type: consts.S_DRAW_CARDS,
//     count
//   })
// )

export const SendAnswerRequest = cards => (
  JSON.stringify({
    type: consts.S_SEND_ANSWER,
    cards
  })
)

export const GenerateRandomRequest = count => (
  JSON.stringify({
    type: consts.S_GENERATE_RANDOM,
    count
  })
)

export const PickWinnerRequest = player => (
  JSON.stringify({
    type: consts.S_PICK_WINNER,
    player
  })
)

export const GetAnswersRequest = player => (
  JSON.stringify({
    type: consts.S_GET_ANSWERS
  })
)

export const NextPlayerRequest = () => (JSON.stringify({
  type: consts.S_NEXT_PLAYER
}))