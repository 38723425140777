import React from 'react'
import {
  CoverContainer, CoverText, TextContainer
} from './style'

function Cover({ randomWon }) {
  return (
    <CoverContainer>
      <TextContainer>
        <CoverText>Random Won</CoverText>
        <CoverText>Fatality</CoverText>
      </TextContainer>
    </CoverContainer>
  )
}

Cover.propTypes = {}

export default Cover
