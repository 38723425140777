import styled from 'styled-components'


export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
`

export const DrawCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 4rem;
  }
`

export const CardsContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

`