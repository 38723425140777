import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import useWebSocket, {ReadyState} from 'react-use-websocket';

import * as Requests from '../../models/requests';
import * as Constants from '../../utils/consts';
import {
  GameContainer,
} from './style';
import {GameMode} from '../../models/Enums';
import BlackCardPlayer from '../../components/BlackCardPlayer/BlackCardPlayer';
import WhiteCardPlayer from '../../components/WhiteCardPlayer/WhiteCardPlayer';
import { classNames } from 'primereact/utils';

let wsBaseUrl = ""

if (process.env.REACT_APP_NODE_ENV === "production") {
  wsBaseUrl = `wss://${ process.env.REACT_APP_BACKEND_URL }/ws`
} else {
  wsBaseUrl = `ws://${ process.env.REACT_APP_BACKEND_URL }/ws`
}



function Board({
  gameCode,
  playerInfo,
  onReadyStateChanged,
  onRandomWon,
  onNavBarPropertiesChanged
}) {
  const [mode, setMode] = useState(GameMode.ANSWERER)
  const [didStart, setDidStart] = useState(false)
  // Answer Mode
  const [whiteCards, setWhiteCards] = useState([])
  const [winner, setWinner] = useState(false)
  const [points, setPoints] = useState(playerInfo.score)
  // Question Mode
  const [questionCard, setQuestionCard] = useState('')
  const [requiredAnswers, setRequiredAnswers] = useState(1)
  const [requiredExtraAnswers, setRequiredExtraAnswers] = useState(0)
  const [answers, setAnswers] = useState({})
  const [answeredPlayers, setAnsweredPlayers] = useState([])
  const [totalNumberOfAnswers, setTotalNumberOfAnswers] = useState(1)

  const {
    sendMessage,
    lastMessage,
    readyState,
  } = useWebSocket(`${wsBaseUrl}/${ gameCode }/${ playerInfo.id.replace(/ /g,"") }`, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });
  
  useEffect(() => {
    if (readyState === ReadyState.OPEN && !didStart) {
      setDidStart(true)
    }
    console.log("readyState: ", ReadyState[readyState])
    onReadyStateChanged(readyState);
  }, [readyState, setDidStart, didStart, onReadyStateChanged])

  useEffect(() => {
    if (didStart) {
      sendMessage(Requests.StartGameRequest())
    }
  }, [didStart, sendMessage])


  useEffect(() => {
    console.log(readyState);
  },[readyState])

  useEffect(() => {
    if (lastMessage) {
      try {
        const socketMessageData = JSON.parse(lastMessage.data);
        console.debug("lastMessage: ", socketMessageData.type)
        switch (socketMessageData.type) {
          case Constants.S_START_GAME:
            console.log('connected to game')
            break
          case Constants.S_DRAW_CARDS:
            console.log(`Cards Drawn - ${socketMessageData.cards}`)
            setWhiteCards(socketMessageData.cards)
            break
          case Constants.S_GET_QUESTION:
            console.log(socketMessageData)
            if (questionCard !== socketMessageData.question) {
              setQuestionCard(socketMessageData.question)
            }
            if (socketMessageData.current_player === playerInfo.id) {
              setMode(GameMode.ASKER)
            } else {
              setRequiredAnswers(socketMessageData.required_answers)
              setRequiredExtraAnswers(socketMessageData.required_extra_cards)
            }
            break
          case Constants.S_GET_ANSWERS:
            setAnswers(socketMessageData.answers)
            break
          case Constants.S_WINNER:
            setWinner(true)
            setPoints(prev => prev + 1)
            break
          case Constants.S_NEXT_PLAYER:
            setQuestionCard('')
            setRequiredAnswers(0)
            setAnswers({})
            setAnsweredPlayers([])
            setTotalNumberOfAnswers(0)
            setWinner(false)
            setRequiredExtraAnswers(0)
            break
          case Constants.S_STOP_GAME:
            onRandomWon()
            break
          case Constants.S_SEND_ANSWER:
            setAnsweredPlayers(socketMessageData.players)
            setTotalNumberOfAnswers(socketMessageData.total_players)
            break
          default:
            break
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [lastMessage, setAnswers, setWinner, setPoints, setQuestionCard, setWhiteCards, onRandomWon, playerInfo.id, questionCard])

  const handleOnSendAnswer = pickedAnswers => {
    const sentAnswers = pickedAnswers.map(a => whiteCards.find(wc => wc.text === a))
    sendMessage(Requests.SendAnswerRequest(sentAnswers))
    setWhiteCards(oldVal => oldVal.filter(c => sentAnswers.indexOf(c) === -1))
  }

  const handleModeChanged = mode => {
    setMode(mode)
  }

  const handleOnGetAnswers = () => {
    sendMessage(Requests.GetAnswersRequest())
  }

  const handleOnNextPlayer = () => {
    sendMessage(Requests.NextPlayerRequest())
  }

  const handleOnPickWinner = player => {
    sendMessage(Requests.PickWinnerRequest(player))
  }

  const handlePickedAnswerCards = cards => {
    onNavBarPropertiesChanged({
      "numOfPickedAnswers": cards.length,
    })
  }

  const renderGameMode = mode => (
    mode === GameMode.ASKER
        ? <BlackCardPlayer
          onModeChange={ handleModeChanged }
          answers={ answers }
          questionCard={ questionCard }
          onGetAnswersClicked={ handleOnGetAnswers }
          onNextPlayerClicked={ handleOnNextPlayer }
          onPickWinnerClicked={ handleOnPickWinner }
          answeredPlayers={ answeredPlayers }
          totalNumberOfAnswers={ totalNumberOfAnswers }/>
        : <WhiteCardPlayer
          questionCard={questionCard}
          requiredAnswers={ requiredAnswers }
          sendMessage={ sendMessage }
          whiteCards={ whiteCards }
          onSendAnswer={ handleOnSendAnswer }
          onPickedAnswerCards={ handlePickedAnswerCards }
          points={ points }

      winner={ winner }/>
  )

  return (
    <GameContainer className={classNames("board-container")}>
      { renderGameMode(mode) }
    </GameContainer>
  );
}

Board.propTypes = {
  sendMessage: PropTypes.func,
  playerInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    score: PropTypes.number
  }),
  gameCode: PropTypes.string.isRequired,
}

Board.defaultProps = {
  sendMessage: () => {},
  onRandomWon: () => {},
  omNavBarPropertiesChanged: () => {},
}

export default Board
