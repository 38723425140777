import React from 'react'
import PropTypes from 'prop-types'

import {
  CarouselWrapper,
  Container,
  SendAnswerButton,
} from './style'
import WhiteCard from './WhiteCard'
import classNames from 'classnames'

function WhiteCardsCarousel({
  value,
  onCardClicked,
  selectedCards,
  onSendAnswerClicked,
  disableSendAnswerButton
}) {

  const renderWhiteCard = (card) => {
    const selectedIndex = selectedCards.indexOf(card.text)

    return (
      <WhiteCard
        text={card.text}
        key={card.text}
        isSelected={selectedIndex > -1}
        selectedCardIndex={selectedIndex}
        onCardClicked={onCardClicked}/>
    )
  }

  return (
    <Container className={classNames("white-card-carousel--container")}>
        <CarouselWrapper className={classNames("white-card-carousel--container-wrapper")}>
          {value.map(renderWhiteCard)}
        </CarouselWrapper>
      {value.length > 0 &&
        <SendAnswerButton
          onClick={() => onSendAnswerClicked()}
          disabled={disableSendAnswerButton || selectedCards.length === 0}>שלח תשובה</SendAnswerButton>}
    </Container>
  )
}

WhiteCardsCarousel.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({ "text": PropTypes.string, "category": PropTypes.string })).isRequired,
  onCardClicked: PropTypes.func.isRequired
}

WhiteCardsCarousel.defaultProps = {
  disableSendAnswerButton: false
}

export default WhiteCardsCarousel
