import {Button} from 'primereact/button';
import styled from 'styled-components';
import { C_WINDOW_HEIGHT } from '../../utils/consts';


export const LoginContainer = styled.div`
  margin: 1rem auto;
  display: grid;
  grid-template-rows: 14rem 1fr 5rem;
  height: ${C_WINDOW_HEIGHT};
  max-width: 30rem;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 3rem;
  align-self: start;

  span {
    width: 100%;

    input {
      width: 100%;
    }

    h4 {
      margin: 0;
      margin-bottom: 1rem;
    }
  }

`

export const LoginButton = styled(Button)`
  width: 50% !important;
  justify-self: center;
`

export const Image = styled.img`
  justify-self: center;
  margin: 3rem 0 2rem 0;
  object-fit: scale-down;
  width: 10rem;
  height: 10rem;

  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

export const DailyKeyInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`