import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext'
import {
  InputsContainer,
  LoginButton,
  LoginContainer,
  Image,
  DailyKeyInputContainer
} from './style'
import axios from "axios";
import { Button } from 'primereact/button';
import DeckSelectionContainer from '../../components/DeckSelectionContainer/DeckSelectionContainer';


function Login({
  onUserLoggedIn,
}) {
  const [gameCode, setGameCode] = useState(sessionStorage.getItem("gameCode") ?? "");
  const [userName, setUserName] = useState(localStorage.getItem("userName") ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const [availableDecks, setAvailableDecks] = useState([]);
  const [selectedDecks, setSelectedDecks] = useState([]);
  const [dailyKey, setDailyKey] = useState("");
  const [didAuthenticate, setDidAuthenticate] = useState(Boolean(sessionStorage.getItem("didAuthenticate")))
  const [isLoadingAuthentication, setIsLoadingAuthentication] = useState(false)

  useEffect(() => {
    axios.get('decks')
      .then(response => {
        setAvailableDecks(response.data.decks);
        setSelectedDecks(response.data.decks)
      })
      .catch(error => {
        console.error(error);
      });
  }, [setAvailableDecks])

  const onGameCodeInputChange = (e) => {
    setGameCode(e.target.value.substring(0, 4).toUpperCase());
  }

  const onUserNameInputChange = (e) => {
    setUserName(e.target.value.substring(0, 40));
  }

  const onDailyKeyInputChange = (e) => {
    setDailyKey(e.target.value)
  }

  const onDailyKeyAuthenticateClicked = () => {
    setIsLoadingAuthentication(true);
    setInterval(() => {
      setIsLoadingAuthentication(false);
      setDidAuthenticate(dailyKey === process.env.REACT_APP_DAILY_KEY && process.env.REACT_APP_DAILY_KEY !== null);
      sessionStorage.setItem("didAuthenticate", dailyKey === process.env.REACT_APP_DAILY_KEY)
    }, 2000)
  }

  const handleStartJoinGamePressed = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(`games/${gameCode}/join`, { name: userName });
      onUserLoggedIn(gameCode, res.data.player, userName);
    } catch (e) {
      switch (e.response.status) {
        case 404:
          alert("Game not found");
          break
        case 400:
          alert(e.response.data.message);
          break
        default:
          // console.log(e.response.data.message);
          break
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleCreateGamePressed = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post('game', { decks: selectedDecks });
      setGameCode(res.data)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false);
    }
  }

  const renderButtonName = () => {
    return isLoading
      ? ''
      : gameCode
        ? 'להצטרף למשחק'
        : 'ליצור משחק'
  }

  const isButtonDisabled = () => {
    
  }

  const onDeckPicked = (d, isPicked) => {
    let newSelectedDecks = [...selectedDecks];
    if (isPicked) {
      newSelectedDecks.push(d);
    } else {
      newSelectedDecks.splice(selectedDecks.indexOf(d), 1)
    }
    setSelectedDecks(newSelectedDecks);
  }

  return (
    <LoginContainer>
      <Image src={`${process.env.PUBLIC_URL}/assets/images/me_gusta.png`} alt="Gush Logo" />
      <InputsContainer>
        <span>
          <h4>הסיסמא היומית</h4>
          <DailyKeyInputContainer>
            <InputText placeholder='XXXX' value={dailyKey} onChange={onDailyKeyInputChange} disabled={didAuthenticate} />
            <Button disabled={didAuthenticate}
              loading={isLoadingAuthentication}
              onClick={e => onDailyKeyAuthenticateClicked()}>
              {isLoadingAuthentication ? "" : didAuthenticate ? "V" : "בדוק"}
            </Button>
          </DailyKeyInputContainer>
        </span>
        <span>
          <h4>קוד משחק</h4>
          <InputText placeholder='XXXX' value={gameCode} onChange={onGameCodeInputChange} />
        </span>
        <span>
          <h4>{`מי אני ${userName.length}/40 `}</h4>
          <InputText placeholder='YOUR NAME' value={userName} onChange={onUserNameInputChange} />
        </span>
        {!gameCode && 
          <DeckSelectionContainer
            availableDecks={availableDecks}
            selectedDecks={selectedDecks}
            onDeckPicked={onDeckPicked}/>}
      </InputsContainer>
      <LoginButton
        disabled={isButtonDisabled()}
        onClick={() => gameCode ? handleStartJoinGamePressed() : handleCreateGamePressed()}
        loading={isLoading}
        loadingIcon="pi pi-spin pi-sun">{renderButtonName()}</LoginButton>
    </LoginContainer>
  )
}

Login.propTypes = {
  onUserLoggedIn: PropTypes.func.isRequired
}

export default Login
