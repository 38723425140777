import React from 'react'
import styled from 'styled-components'
import DeckBox from './DeckBox';
import classNames from 'classnames';

export const DecksPickContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default function DeckSelectionContainer({
  onDeckPicked,
  availableDecks,
  selectedDecks
}) {
  
  const isDeckSelected = d => selectedDecks.indexOf(d) !== -1

  const renderDeckBox = (d) => (
      <DeckBox
          key={d}
          onDeckPicked={onDeckPicked}
          deck={d}
          isSelected={isDeckSelected(d)}/>
        )
  
  const renderDecksBoxes = decks => availableDecks.map(renderDeckBox)
  const className = classNames("DecksContainer")
  return (
    <DecksPickContainer className={className}>
      {renderDecksBoxes(availableDecks)}
    </DecksPickContainer>
  )
}
