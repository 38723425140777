import React, { useEffect } from 'react'
import { useState } from 'react';
import styled from 'styled-components'
import Login from '../Login'
import GushMenuBar from '../../components/GushMenuBar/GushMenuBar';
import Board from '../Board/Board';
import { ReadyState } from 'react-use-websocket';
import Cover from '../../components/Cover/Cover';
import Splash from "../../components/Splash";
import { useParams } from 'react-router';

const ContentContainer = styled.div`
  margin: 0 auto;
`

export default function Game() {

  let { game } = useParams();

  const [gameCode, setGameCode] = useState(game);
  const [playerInfo, setPlayerInfo] = useState({});
  const [userName, setUserName] = useState('');
  const [didLogIn, setDidLogin] = useState(false);
  const [connectionState, setConnectionState] = useState(ReadyState.CLOSED);
  const [navBarProperties, setNavBarProperties] = useState({})
  const [randomWon, setRandomWon] = useState(false)
  const [didShowSplash, setDidShowSplash] = useState(sessionStorage.getItem("didShowSplash") === "true")

  useEffect(() => {
    if (game) {
      setGameCode(game)
    }
  }, [game])


  if (sessionStorage.getItem("didShowSplash") !== "true") {
    setInterval(() => {
      setDidShowSplash(true)
      sessionStorage.setItem("didShowSplash", true.toString())
    }, 5000)
  }

  const handleStartGamePressed = (gameCode, playerInfo, userName) => {
    localStorage.setItem("userName", userName)
    sessionStorage.setItem("gameCode", gameCode)
    setGameCode(gameCode);
    setPlayerInfo(playerInfo);
    setUserName(userName)
    setDidLogin(true);
  }

  const handleConnectionStateChanged = (readyState) => {
    setConnectionState(readyState);
  }

  const handleRandomWon = () => {
    setRandomWon(true)
  }

  const handleNavPropertiesChanges = newProps => {
    setNavBarProperties(newProps)
  }

  return (
    !didShowSplash
      ? <Splash />
      : randomWon
        ? <Cover randomWon={randomWon} />
        : <>
          <ContentContainer>
            {!didLogIn
              ? <Login onUserLoggedIn={handleStartGamePressed} />
              : <>
                <GushMenuBar
                  connectionState={connectionState}
                  playerName={userName}
                  gameCode={gameCode}
                  gameStats={navBarProperties} />
                <Board
                  gameCode={gameCode}
                  playerInfo={playerInfo}
                  onReadyStateChanged={handleConnectionStateChanged}
                  onRandomWon={handleRandomWon}
                  onNavBarPropertiesChanged={handleNavPropertiesChanges} />

              </>}
          </ContentContainer>
        </>
  )
}
