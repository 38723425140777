import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import WhiteCardsCarousel from '../../components/WhiteCardsCarousel';
import BlackCardContainer from '../BlackCardPlayer/BlackCardContainer';
import { CardsContainerDiv } from './style';
import { classNames } from 'primereact/utils';


function WhiteCardPlayer({ whiteCards, onSendAnswer, winner, points, requiredAnswers, questionCard }) {
  const [selectedCards, setSelectedCards] = useState([])
  const [didSendAnswer, setDidSendAnswer] = useState(false)

  useEffect(() => {
    setDidSendAnswer(false);
    console.log("question card changed")
  }, [questionCard, setDidSendAnswer])

  const handleSendAnswerClick = () => {
    if (didSendAnswer) {
      setSelectedCards([])
    } else if (selectedCards.length !== requiredAnswers) {
      return
    } else {
      onSendAnswer(selectedCards)
      setSelectedCards([])
      setDidSendAnswer(true)
    }
  }

  const onCardClicked = (card) => {
    if (selectedCards.indexOf(card) === -1 && selectedCards.length < requiredAnswers) {
      setSelectedCards([...selectedCards, card])
    } else {
      setSelectedCards(selectedCards.filter(c => c !== card))
    }
  }
  return (
    <>
      <div>
        {winner && <h1 style={{ textAlign: 'end' }}>ניצחת!</h1>}
        <p>
          נקודות: {points} |
          קלפים ביד: {whiteCards.length} |
          בחרת: {selectedCards.length}/{requiredAnswers}
        </p>
      </div>
      <CardsContainerDiv>
        {questionCard &&
          <BlackCardContainer
            className={classNames("black-card-container")}
            title={questionCard}
            answers={selectedCards.map(a => ({ text: a }))}
            numRequiredAnswers={requiredAnswers} />}
        <WhiteCardsCarousel
          value={whiteCards}
          onCardClicked={onCardClicked}
          selectedCards={selectedCards}
          onSendAnswerClicked={handleSendAnswerClick} />
      </CardsContainerDiv>
    </>
  )
}

WhiteCardPlayer.propTypes = {
  sendMessage: PropTypes.func,
  whiteCards: PropTypes.arrayOf(PropTypes.shape({ 'text': PropTypes.string, 'category': PropTypes.string })).isRequired,
  onSendAnswer: PropTypes.func,
  onPickedAnswerCards: PropTypes.func,
  winner: PropTypes.bool,
  points: PropTypes.number,
  onDraw: PropTypes.func,
  requiredAnswers: PropTypes.number,
}

WhiteCardPlayer.defaultProps = {
  sendMessage: () => { },
  onSendAnswer: () => { },
  onPickedAnswerCards: () => { },
  onDraw: () => { },
  winner: false,
  points: 0
}

export default WhiteCardPlayer
